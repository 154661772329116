.alert {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 10;
    border-radius: 7px;
}

.alert .content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 15px 10px;
}

.alert .content p {
    padding: 0 5px;
    font-size: 15px;
}

.alert .border {
    width: 10px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

/* ---- Alert Add ---- */

.alert-add, .alert-login-success {
    background-color: #effff9;
}

.alert-add .border, .alert-login-success .border {
    background: linear-gradient(#6fde8e, #61cbd1);
}

.alert-add .content img {
    filter: invert(82%) sepia(41%) saturate(498%) hue-rotate(75deg) brightness(90%) contrast(93%);
}



/* ---- Alert Edit ---- */

.alert-edit {
    background-color: #effff9;
}

.alert-edit .border {
    background: linear-gradient(#6fde8e, #61cbd1);
}

.alert-edit .content img {
    filter: invert(82%) sepia(41%) saturate(498%) hue-rotate(75deg) brightness(90%) contrast(93%);
}

/* ---- Alert Delete ---- */

.alert-delete, .alert-login-failed {
    background-color: #ffd8d8;
}

.alert-delete .border, .alert-login-failed .border {
    background-color: #C02A2A;
}

.alert-delete .content img {
    filter: invert(17%) sepia(51%) saturate(4433%) hue-rotate(347deg) brightness(108%) contrast(87%);
}

.alert-login-failed p{
    color: #C02A2A;
}

/* ---- Alert Restore ---- */

.alert-restore {
    background-color: #effff9;
}

.alert-restore .border {
    background: linear-gradient(#6fde8e, #61cbd1);
}

.alert-restore .content img {
    filter: invert(82%) sepia(41%) saturate(498%) hue-rotate(75deg) brightness(90%) contrast(93%);
}