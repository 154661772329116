.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  margin: 5px 0;
  border: none;
  color: black;
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 15px;
  transition: 0.5s all;
}
.btn img {
  padding: 0 5px;
}
.btn:hover {
  cursor: pointer;
}
.btn-add {
  color: white;
  background: linear-gradient(#6fde8e, #61cbd1);
}

.btn-add img {
  filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(139deg)
    brightness(104%) contrast(100%);
}

.btn-delete:hover {
  background: #ffd8d8;
}

.btn-edit:hover,
.btn-download:hover {
  background: #effff9;
}
