@import url('utils/fonts.css');
* {
  transition: 0.5s all;
}
.home {
  background-color: #fafafa;
  min-height: 100vh;
  overflow: hidden;
}
.container {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5vh;
}
.container-reports {
  background-color: white;
  max-height: 80vh;
  min-height: 80vh;
  width: calc(100vw - 4vw);
  margin: 0 auto;
  padding: 15px;
  border-radius: 22px;
  overflow-y: scroll;
  position: relative;
}
/* .container-reports::-webkit-scrollbar {
  display: none;
} */
.report-null {
  height: 60px;
  width: 100%;
}
.header-container {
  background-color: white;
  padding-top: 10px;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 100;
  width: calc(100% - 4vw - 25px);
  transform: translateX(-50%);
}
.header-reports {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  /* background-color: red; */
  border-radius: 12px;
  padding: 20px 0;

  margin: 5px 0;
}
.header-reports h3 {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  font-size: 15px;
  width: calc(100% - 12.5%);
}
.list-reports {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  /* margin-top: 8vh; */
}
.list-reports > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
}
.report {
  transition: 0.5s all;
}
.report:hover {
  background-color: #fafafa;
}
.head-report {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  margin: 5px 0;
  height: 60px;
  /* background-color: red; */
  transition: 0.5s all;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
  z-index: 99;
}
.head-report p,
.head-report > button {
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  width: calc(100% - 12.5%);
}

.head-report p > button {
  background: none;
  border: none;
  margin-left: 15px;
  padding: 5px;
  /* border: none; */
}
.head-report p > button:hover {
  cursor: pointer;
  /* background-color: red; */
}
.head-report p > button > img {
  height: 22px;
  transition: 0.5s all;
}

.body-report {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  height: 0;
  z-index: -1;
  transition: 0.5s all;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.itinerario {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4px 0;
}
.itinerario > p {
  font-weight: 500;
}
.itinerario span,
.observation span {
  font-size: 14px;
  color: #5fb878;
  font-weight: 600;
  font-style: italic;
}
.observation span {
  justify-content: flex-start;
}
.download > span{
  font-size: 12px;
}
.options {
  display: flex;
  flex-direction: column;
}

.open {
  border-radius: 12px;
  transition: 0.5s all;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
}
.open .head-report {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
}
.open .head-report p > button > img {
  transform: rotate(-180deg);
}
.open .body-report {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  height: 140px;
  transition: 0.5s all;
}

.btnFormAgregar:hover {
  cursor: pointer;
}

.modalAgregar {
  /* display: none; */
}
