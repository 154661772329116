@import url('utils/fonts.css');

:root {
  --menta: #6fde8e;
  --fontInter: 'Inter', sans-serif;
  --fontFranklin: 'Libre Franklin', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  width: 100%;
  height: 12vh;
  padding: 0 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
}
.logo {
  text-decoration: none;
}
.logo h1 {
  font-family: var(--fontFranklin);
  padding: 0.5rem;
  font-size: 28px;
  font-style: italic;
  font-weight: 800;
  background: linear-gradient(#6fde8e, #61cbd1);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.logo > img {
  height: 60px;
}

.menu {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.item-menu {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0 7px;
  padding: 6px 8px;
  color: gray;
}
.item-menu h3 {
  font-size: 16px;
  padding: 0 5px;
  font-weight: 500;
}
.active {
  border-bottom: 3px solid var(--menta);
  color: black;
}

.item-menu > img {
  height: 17px;
}
.item-menu p {
  font-family: var(--fontFranklin);
  font-weight: 500;
  padding: 0 5px;
}

.item-menu:hover {
  cursor: pointer;
}

.panel-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.searchbar {
  position: relative;
}
.searchbar .icon {
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
  height: 17px;
  filter: invert(79%) sepia(15%) saturate(1287%) hue-rotate(83deg)
    brightness(98%) contrast(85%);
}
.searchbar input {
  font-family: var(--fontInter);
  width: 170px;
  padding: 0;
  padding: 7px 0px;
  padding-left: 40px;
  margin: 0 10px;
  border: 2px solid #d8d8d8;
  border-radius: 6px;
  transition: 0.5s all;
}

.searchbar input[type='number']::-webkit-inner-spin-button,
.searchbar input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.searchbar input[type='number'] {
  -moz-appearance: textfield;
}

.searchbar input:hover{
  border: 2px solid #aeaeae;
}
.searchbar input:focus {
  border: 2px solid #aeaeae;
  outline: none;
}
.searchbar input::placeholder {
  color: #d8d8d8;
  transition: 0.5s all;
}
.searchbar input:hover::placeholder{
color: #AEAEAE;
}

