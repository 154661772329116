@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

* {
  font-family: 'Inter', sans-serif;
  transition: 0.5s all;
}

.modal {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: -105px;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s all;
  visibility: hidden;
  opacity: 0;
}

.modalAgregar {
  top: 0;
}

.modal .modal-dialog {
  overflow-y: auto;
  height: auto;
  margin-top: 3vh;
  background-color: white;
  transform: translateY(30px);
  transition: 0.5s all;
  padding: 1.5rem 2rem;
  border-radius: 6px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
}

.modal .modal-delete {
  width: 500px;
}

.modal-delete .modal-content .modal-body {
  text-align: start;
  padding: 35px 25px;
}

.modal .modal-dialog .modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 1.7rem;
}

.modal .modal-dialog .modal-header img {
  height: 24px;
  filter: invert(79%) sepia(15%) saturate(1287%) hue-rotate(83deg) brightness(98%) contrast(85%);
}

.modalEliminar .modal-dialog .modal-header img,
.modalBorrar .modal-dialog .modal-header img {
  height: 24px;
  filter: invert(61%) sepia(96%) saturate(3920%) hue-rotate(326deg) brightness(88%) contrast(98%);
}

.modal .modal-dialog .modal-header h3 {
  background: linear-gradient(#6fde8e, #61cbd1);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  padding: 0 0.5rem;
}

.modalEliminar .modal-dialog .modal-header h3,
.modalBorrar .modal-dialog .modal-header h3 {
  background: #DF5050;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  padding: 0 0.5rem;
}

.modal-body form .head-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.modal-body form .head-form .input {
  width: 100%;
}

.modal-body form .puntajes-valores {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vh;
}

.modal-body form .puntaje-valor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body form .porcentajes-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-body form .porcentajes-total .input {
  width: 100%;
}

.modal-body form .input {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 7px 20px;
}

.modal-body form .subir-archivo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
}

input[type=file]:hover {
  cursor: pointer;
}

input[type=file]::file-selector-button {
  padding: 8px;
  border-radius: .2em;
  background: linear-gradient(#6fde8e, #61cbd1);
  color: white;
  border: none;
  transition: 1s;
}

.modal-body form .subir-archivo input[type='file']:hover {
  cursor: pointer;
}

.modal-body form .subir-archivo button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #fafafa;
  border: none;
  border-radius: 6px;
}

.modal-body form .input input,
textarea {
  padding: 0.5rem;
  border-radius: 6px;
  border: 2px solid #d8d8d8;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  transition: 0.5s all;
}

.modal-body form .input input:hover {
  border: 2px solid #aeaeae;
}

.modal-body form .input input:hover::placeholder {
  color: #aeaeae;
}

.modal-body form .input input:focus {
  border: 2px solid #aeaeae;
  outline: none;
}

textarea {
  font-size: 16px;
  text-align: start;
  font-style: normal;
  padding: 0.7rem;
}

.modal-body form .input input::placeholder {
  color: #4d4d4d;
  transition: 0.5s all;
}

.modal-body form .input input[type='number']::-webkit-inner-spin-button,
.modal-body form .input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modal-body form .input input[type='number'] {
  -moz-appearance: textfield;
}

/* .modal-body form .head-form > .input {
  margin: 0 20px;
} */

textarea {
  width: 100%;
}

.modal-body form .btn-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
  margin-top: 30px;
}

.modal-body form .btn-actions>button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 0.6rem;
  margin: 0 10px;
  font-size: 15px;
  background-color: #fafafa;
  border: none;
  border-radius: 6px;
}

.modal-body form .btn-actions>button:hover {
  cursor: pointer;
}

.modal-body form .btn-actions>button:first-child:hover {
  background-color: #ffd8d8;
}

.modal-body form .btn-actions>button img {
  filter: invert(100%) sepia(5%) saturate(21%) hue-rotate(139deg) brightness(104%) contrast(100%);
  padding: 0 0.1rem;
}

.modal-body form .btn-actions>button:last-child {
  color: white;
  background: linear-gradient(#6fde8e, #61cbd1);
}

.modal-body form .delete>button:last-child {
  color: white;
  background: #DF5050;
}


.show {
  visibility: visible;
  opacity: 1;
}

.show .modal-dialog {
  transform: translateY(0px);
}

.modalAlert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: green;
  border-radius: 15px;
  width: 95%;
  padding: 5px 15px;
  color: white;
  opacity: 0;
  visibility: hidden;
}

.modalAlert.show {
  opacity: 1;
  visibility: visible;
}