.switch {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ededed;
  margin: 0 auto;
  width: 55px;
  padding: 3px;
  border-radius: 20px;
}
.switch .color {
  height: 17px;
  width: 17px;
  border-radius: 25px;
  margin: 0 5px;
}

.color:hover {
  padding: 7px;
  cursor: pointer;
  border: 3px solid #c9c9c9;
}
.color-0{
  background-color: white;
}
.color-1 {
  background-color: #e35151;
}
.color-2 {
  background-color: #ffab48;
}
.color-3 {
  background-color: #6fde8e;
}
.switch-arrow {
}
.spin{
    transform: rotate(-180deg);
}
.switch-arrow:hover {
  cursor: pointer;
}

.switch-menu {
  background-color: #ededed;
  padding: 3px;
  border-radius: 15px;
  position: absolute;
  top: 29px;
  left: -5px;
  display: flex;
  flex-direction: row;
}
.switch-menu > .color {
  margin: 0 2px;
}
