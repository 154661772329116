.index {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: white;
    padding: 25px;
    box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.49);
    -webkit-box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.49);
    border-radius: 15px;
}

.title_form {
    margin-bottom: 15px;
}

.inputs {
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

.inputs .label_form {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    font-style: italic;
    font-size: 14px;
    color: #aeaeae;
}

.inputs .input_form {
    position: relative;
    z-index: 1;
    padding: 0.8rem;
    border-radius: 6px;
    border: 2px solid #d8d8d8;
    text-align: center;
    font-style: italic;
    font-weight: 500;
    transition: 0.5s all;
    background: none;
}

.focus {
    top: 15% !important;
    background-color: white;
    padding: 2px 4px;
    z-index: 2 !important;
}

.btnIngresar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 12px;
    border: none;
    color: black;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 15px;
    transition: 0.5s all;
    color: white;
    background: linear-gradient(#6fde8e, #61cbd1);
}